/* userback feedback button */
.userback-button-container .userback-controls .userback-controls-step:first-of-type::before {
  content: '';
  /* NOTE: this gets converted to a static media asset via webpack */
  background: url('/public/images/feedback.jpg');
  background-size: contain;
  width: 356px !important;
  height: 117px !important;
  display: block;
  margin-bottom: 25px;
}

.userback-button-container .userback-button:not(.react-button) {
  display: none !important;
}

ubfooter {
  display: none !important;
}

.userback-button-container .userback-feedback-icon {
  font-size: 1.5em;
  color: #282828;
}
