.prims-diff-highlight > pre .token.deleted:not(.prefix) {
  background-color: rgba(255, 0, 0, 0.1);
  color: inherit;
  display: block;
}

.prism-diff-highlight > pre .token.inserted:not(.prefix) {
  background-color: rgba(0, 255, 128, 0.1);
  color: inherit;
  display: block;
}
